<template>
    <v-responsive max-width="700" class="overflow-visible">

        <v-row class="mt-4 px-1" align="center">
            <v-col cols="auto" class="headline">메인화면 관리</v-col>
            <v-spacer/>
            <v-col cols="auto" class="py-0">
            </v-col>
        </v-row>
        
        <v-card>
            <v-card-title>
                <span class="subtitle-2">전체사례 숫자</span>
                <v-spacer/>
                <span><v-text-field v-model="mainAll.count" outlined dense hide-details /></span>
            </v-card-title>
            <v-divider />
            <v-card-title>
                <span class="subtitle-2">클라리베인 숫자</span>
                <v-spacer/>
                <span><v-text-field v-model="mainClariVein.count" outlined dense hide-details /></span>
            </v-card-title>
            <v-divider />
            <v-card-title>
                <span class="subtitle-2">베나실 숫자</span>
                <v-spacer/>
                <span><v-text-field v-model="mainVenaSeal.count" outlined dense hide-details /></span>
            </v-card-title>
        </v-card>

        <v-layout class="mt-4">
            <span class="mx-auto">
                <v-btn color="primary" @click="save()">저장</v-btn>
            </span>
        </v-layout>

    </v-responsive>
</template>

<script>
import XLSX from "xlsx";
import api from "@/api";
import { mdiMicrosoftExcel } from "@mdi/js";

export default {
    components: {
    },
    data(){
        return {
            mdiMicrosoftExcel,

            mainAll: {
                code: "all",
                count: 0
            },
            mainClariVein: {
                code: "clarivein",
                count: 0,
            },
            mainVenaSeal: {
                code: "venaseal",
                count: 0
            }
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            var { mains } = await api.console.easyleg.mains.gets({});

            var mainAll = mains.find(main => main.code == "all");
            if(mainAll) this.mainAll = mainAll;

            var mainClariVein = mains.find(main => main.code == "clarivein");
            if(mainClariVein) this.mainClariVein = mainClariVein;

            var mainVenaSeal = mains.find(main => main.code == "venaseal");
            if(mainVenaSeal) this.mainVenaSeal = mainVenaSeal;
        },

        async save(){

            var mains = [this.mainAll, this.mainClariVein, this.mainVenaSeal];

            for(let main of mains){
                await api.console.easyleg.mains[main._id ? "put" : "post"](main);
            }

            alert("저장되었습니다");
        }

    }
}
</script>