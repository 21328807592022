<template>
    <v-responsive max-width="800">

        <v-row class="mt-4 px-1" align="center">
            <v-col cols="auto" class="headline">고객리뷰 관리</v-col>
            <v-spacer/>
            <v-col cols="auto" class="py-0">
            </v-col>
        </v-row>
        
        <v-data-table :items="reviews" :headers="reviewsHeaders" hide-default-footer disable-sort class="mt-4 mx-1 elevation-1">
            <template #[`item.thumb`]="{item}">
                <span class="d-inline-block mx-auto">
                    <v-img :src="item?.thumb?.src" height="100" width="100" contain />
                </span>
            </template>
            <template #[`item.isMain`]="{item}">
                <span class="d-inline-block mx-auto">
                    <v-switch v-model="item.isMain" dense hide-details class="mt-0" @change="update({ _id: item._id, isMain: item.isMain })" />
                </span>
            </template>
            <template #[`item.createdAt`]="{item}">
                {{ $dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template #[`item.actions`]="{item}">
                <v-icon small @click="edit(item)">mdi-pencil</v-icon>
                <v-icon small class="ml-1" @click="remove(item)">mdi-delete</v-icon>
            </template>
        </v-data-table>

        <v-pagination v-model="page" :length="pageCount" total-visible="11" class="mt-4 mb-4" @input="search(true)"/>

        <v-btn color="accent" fab fixed bottom right dark @click="create"><v-icon>mdi-pencil</v-icon></v-btn>

    </v-responsive>
</template>

<script>
import XLSX from "xlsx";
import api from "@/api";
import { mdiMicrosoftExcel } from "@mdi/js";

export default {
    components: {
    },
    data(){
        return {
            mdiMicrosoftExcel,

            showSearch: true,

            filter: {
                searchKey: this.$route.query.searchKey || null,
                searchValue: this.$route.query.searchValue || null
            },

            searchKeys: [
                { text: ":: 전체 ::", value: null },
                { text: "업체명", value: "companyName" },
            ],

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            reviews: [],
            reviewsHeaders: [
                { text: "순서", value: "no", align: "center" },
                { text: "썸네일", value: "thumb", align: "center" },
                { text: "메인등록", value: "isMain", align: "center" },
                { text: "작성일자", value: "createdAt", align: "center", width: 200 },
                { text: "actions", value: "actions", align: "center", width: 100 },
            ]
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            await this.search();
        },

        async search(routable = false){
            if(routable){
                this.$router.push({
                    query: { ...this.filter, page: this.page }
                });
            }

            let { summary, reviews } = await api.console.easyleg.reviews.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                },
                params: this.filter
            });

            this.reviews = reviews;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },

        create(){
            this.$router.push(`/console/easyleg/reviews/create`);
        },

        edit(review){
            this.$router.push(`/console/easyleg/reviews/${review._id}`);
        },

        async update(review){
            await api.console.easyleg.reviews.put(review);
        },

        async remove(review){
            if(confirm("정말 삭제하시겠습니까?")){
                await api.console.easyleg.reviews.delete(review);
                alert("삭제되었습니다");
                this.search();
            }
        },

        // async excel(){
        //     let { reviews } = await api.console.easyleg.reviews.gets({
        //         headers: { limit: 0 },
        //         params: this.filter
        //     });

        //     var datas = [];
        //     reviews.forEach(review => {
        //         datas.push({
        //             "아이디": review?.username,
        //             "연락처": review?.phone,
        //             "이메일": review?.email,
        //             "생성일자": review?.createdAt ? this.$dayjs(review?.createdAt).format("YYYY-MM-DD HH:mm:ss") : "-"
        //         });
        //     });

        //     var workbook = new XLSX.utils.book_new();
        //     var worksheet = XLSX.utils.json_to_sheet(datas);

        //     XLSX.utils.book_append_sheet(workbook, worksheet, "제휴업체 목록");
        //     XLSX.writeFile(workbook, "제휴업체 목록.xlsx");
        // }
    }
}
</script>