var render = function render(){
  var _vm$review, _vm$review$thumb, _vm$review2, _vm$review2$images, _vm$review2$images$;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-responsive', {
    attrs: {
      "max-width": "600"
    }
  }, [_c('v-row', {
    staticClass: "mt-4 px-1",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "headline",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.$route.params._id ? _c('span', [_vm._v("리뷰 상세")]) : _c('span', [_vm._v("리뷰 작성")])]), _c('v-spacer')], 1), _c('v-card', {
    staticClass: "mx-1 mt-3"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2"
  }, [_vm._v("기본정보")]), _c('v-divider'), _c('v-card-title', {
    staticClass: "align-center"
  }, [_c('span', {
    staticClass: "subtitle-2"
  }, [_vm._v("순서")]), _c('v-spacer'), _c('span', [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.review.no,
      callback: function ($$v) {
        _vm.$set(_vm.review, "no", $$v);
      },
      expression: "review.no"
    }
  })], 1)], 1), _c('v-divider'), _c('v-card-title', {
    staticClass: "align-center"
  }, [_c('span', {
    staticClass: "subtitle-2"
  }, [_vm._v("썸네일")]), _c('v-spacer'), _c('span', [_c('v-img', {
    attrs: {
      "src": _vm.createObjectURL(_vm.thumb) || ((_vm$review = _vm.review) === null || _vm$review === void 0 ? void 0 : (_vm$review$thumb = _vm$review.thumb) === null || _vm$review$thumb === void 0 ? void 0 : _vm$review$thumb.src),
      "width": "100",
      "height": "100"
    }
  })], 1), _c('span', [_c('v-file-btn', {
    attrs: {
      "color": "primary"
    },
    model: {
      value: _vm.thumb,
      callback: function ($$v) {
        _vm.thumb = $$v;
      },
      expression: "thumb"
    }
  }, [_vm._v("변경")])], 1)], 1), _c('v-divider'), _c('v-card-title', {
    staticClass: "align-center"
  }, [_c('span', {
    staticClass: "subtitle-2"
  }, [_vm._v("이미지")]), _c('v-spacer'), _c('span', [_c('v-img', {
    attrs: {
      "src": _vm.createObjectURL(_vm.image) || ((_vm$review2 = _vm.review) === null || _vm$review2 === void 0 ? void 0 : (_vm$review2$images = _vm$review2.images) === null || _vm$review2$images === void 0 ? void 0 : (_vm$review2$images$ = _vm$review2$images[0]) === null || _vm$review2$images$ === void 0 ? void 0 : _vm$review2$images$.src),
      "width": "100",
      "height": "100"
    }
  })], 1), _c('span', [_c('v-file-btn', {
    attrs: {
      "color": "primary"
    },
    model: {
      value: _vm.image,
      callback: function ($$v) {
        _vm.image = $$v;
      },
      expression: "image"
    }
  }, [_vm._v("변경")])], 1)], 1), _c('v-divider'), _c('v-card-title', {
    staticClass: "align-center"
  }, [_c('span', {
    staticClass: "subtitle-2"
  }, [_vm._v("메인등록")]), _c('v-spacer'), _c('span', [_c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.review.isMain,
      callback: function ($$v) {
        _vm.$set(_vm.review, "isMain", $$v);
      },
      expression: "review.isMain"
    }
  })], 1)], 1)], 1), _c('v-row', {
    staticClass: "my-6"
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.save();
      }
    }
  }, [_vm._v("저장")])], 1), _c('v-spacer')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }