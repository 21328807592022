var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-responsive', {
    attrs: {
      "max-width": "1024"
    }
  }, [_c('v-row', {
    staticClass: "mt-4 px-1",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "headline",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.$route.params._admin ? _c('span', [_vm._v("관리자 상세")]) : _c('span', [_vm._v("관리자 생성")])]), _c('v-spacer')], 1), _c('v-card', {
    staticClass: "mx-1 mt-3"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2"
  }, [_vm._v("기본정보")]), _c('v-card-text', [_c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "아이디",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.admin.username,
      callback: function ($$v) {
        _vm.$set(_vm.admin, "username", $$v);
      },
      expression: "admin.username"
    }
  })], 1), _c('v-col', [_c('v-password-field', {
    attrs: {
      "label": "비밀번호",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.admin.password,
      callback: function ($$v) {
        _vm.$set(_vm.admin, "password", $$v);
      },
      expression: "admin.password"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "담당자(이름)",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.admin.name,
      callback: function ($$v) {
        _vm.$set(_vm.admin, "name", $$v);
      },
      expression: "admin.name"
    }
  })], 1), _c('v-col')], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "담당자(연락처)",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.admin.phone,
      callback: function ($$v) {
        _vm.$set(_vm.admin, "phone", $$v);
      },
      expression: "admin.phone"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "담당자(이메일)",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.admin.email,
      callback: function ($$v) {
        _vm.$set(_vm.admin, "email", $$v);
      },
      expression: "admin.email"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "우편번호",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.admin.postcode,
      callback: function ($$v) {
        _vm.$set(_vm.admin, "postcode", $$v);
      },
      expression: "admin.postcode"
    }
  })], 1), _c('v-col')], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "기본주소",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.admin.address1,
      callback: function ($$v) {
        _vm.$set(_vm.admin, "address1", $$v);
      },
      expression: "admin.address1"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "상세주소",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.admin.address2,
      callback: function ($$v) {
        _vm.$set(_vm.admin, "address2", $$v);
      },
      expression: "admin.address2"
    }
  })], 1)], 1)], 1), _c('v-card-actions')], 1), _c('v-row', {
    staticClass: "my-6"
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1), _c('v-spacer')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }