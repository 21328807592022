var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-responsive', {
    attrs: {
      "max-width": "800"
    }
  }, [_c('v-row', {
    staticClass: "mt-4 px-1",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "headline",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("고객리뷰 관리")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  })], 1), _c('v-data-table', {
    staticClass: "mt-4 mx-1 elevation-1",
    attrs: {
      "items": _vm.reviews,
      "headers": _vm.reviewsHeaders,
      "hide-default-footer": "",
      "disable-sort": ""
    },
    scopedSlots: _vm._u([{
      key: `item.thumb`,
      fn: function (_ref) {
        var _item$thumb;
        var item = _ref.item;
        return [_c('span', {
          staticClass: "d-inline-block mx-auto"
        }, [_c('v-img', {
          attrs: {
            "src": item === null || item === void 0 ? void 0 : (_item$thumb = item.thumb) === null || _item$thumb === void 0 ? void 0 : _item$thumb.src,
            "height": "100",
            "width": "100",
            "contain": ""
          }
        })], 1)];
      }
    }, {
      key: `item.isMain`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('span', {
          staticClass: "d-inline-block mx-auto"
        }, [_c('v-switch', {
          staticClass: "mt-0",
          attrs: {
            "dense": "",
            "hide-details": ""
          },
          on: {
            "change": function ($event) {
              return _vm.update({
                _id: item._id,
                isMain: item.isMain
              });
            }
          },
          model: {
            value: item.isMain,
            callback: function ($$v) {
              _vm.$set(item, "isMain", $$v);
            },
            expression: "item.isMain"
          }
        })], 1)];
      }
    }, {
      key: `item.createdAt`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(_vm.$dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss")) + " ")];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.edit(item);
            }
          }
        }, [_vm._v("mdi-pencil")]), _c('v-icon', {
          staticClass: "ml-1",
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_vm._v("mdi-delete")])];
      }
    }], null, true)
  }), _c('v-pagination', {
    staticClass: "mt-4 mb-4",
    attrs: {
      "length": _vm.pageCount,
      "total-visible": "11"
    },
    on: {
      "input": function ($event) {
        return _vm.search(true);
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  }), _c('v-btn', {
    attrs: {
      "color": "accent",
      "fab": "",
      "fixed": "",
      "bottom": "",
      "right": "",
      "dark": ""
    },
    on: {
      "click": _vm.create
    }
  }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }