var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-responsive', {
    staticClass: "overflow-visible",
    attrs: {
      "max-width": "700"
    }
  }, [_c('v-row', {
    staticClass: "mt-4 px-1",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "headline",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("메인화면 관리")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  })], 1), _c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "subtitle-2"
  }, [_vm._v("전체사례 숫자")]), _c('v-spacer'), _c('span', [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.mainAll.count,
      callback: function ($$v) {
        _vm.$set(_vm.mainAll, "count", $$v);
      },
      expression: "mainAll.count"
    }
  })], 1)], 1), _c('v-divider'), _c('v-card-title', [_c('span', {
    staticClass: "subtitle-2"
  }, [_vm._v("클라리베인 숫자")]), _c('v-spacer'), _c('span', [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.mainClariVein.count,
      callback: function ($$v) {
        _vm.$set(_vm.mainClariVein, "count", $$v);
      },
      expression: "mainClariVein.count"
    }
  })], 1)], 1), _c('v-divider'), _c('v-card-title', [_c('span', {
    staticClass: "subtitle-2"
  }, [_vm._v("베나실 숫자")]), _c('v-spacer'), _c('span', [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.mainVenaSeal.count,
      callback: function ($$v) {
        _vm.$set(_vm.mainVenaSeal, "count", $$v);
      },
      expression: "mainVenaSeal.count"
    }
  })], 1)], 1)], 1), _c('v-layout', {
    staticClass: "mt-4"
  }, [_c('span', {
    staticClass: "mx-auto"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.save();
      }
    }
  }, [_vm._v("저장")])], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }