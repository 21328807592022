<template>
    <v-responsive max-width="600">

        <v-row class="mt-4 px-1" align="center">
            <v-col cols="auto" class="headline">
                <span v-if="$route.params._id">리뷰 상세</span>
                <span v-else>리뷰 작성</span>
            </v-col>
            <v-spacer/>
        </v-row>

        <v-card class="mx-1 mt-3">
            <v-card-title class="subtitle-2">기본정보</v-card-title>
            <v-divider />
            <v-card-title class="align-center">
                <span class="subtitle-2">순서</span>
                <v-spacer />
                <span>
                    <v-text-field v-model="review.no" outlined dense hide-details />
                </span>
            </v-card-title>
            <v-divider />
            <v-card-title class="align-center">
                <span class="subtitle-2">썸네일</span>
                <v-spacer />
                <span>
                    <v-img :src="createObjectURL(thumb) || review?.thumb?.src" width="100" height="100"/>
                </span>
                <span>
                    <v-file-btn v-model="thumb" color="primary">변경</v-file-btn>
                </span>
            </v-card-title>
            <v-divider />
            <v-card-title class="align-center">
                <span class="subtitle-2">이미지</span>
                <v-spacer />
                <span>
                    <v-img :src="createObjectURL(image) || review?.images?.[0]?.src" width="100" height="100"/>
                </span>
                <span>
                    <v-file-btn v-model="image" color="primary">변경</v-file-btn>
                </span>
            </v-card-title>
            <v-divider />
            <v-card-title class="align-center">
                <span class="subtitle-2">메인등록</span>
                <v-spacer />
                <span>
                    <v-switch v-model="review.isMain" outlined dense hide-details class="mt-0"/>
                </span>
            </v-card-title>
        </v-card>
        
        <v-row class="my-6">
            <v-spacer/>
            <v-col cols="auto">
                <v-btn color="primary" @click="save()">저장</v-btn>
            </v-col>
            <v-spacer/>
        </v-row>

    </v-responsive>
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";
import VPasswordField from "@/components/plugins/vuetify/v-password-field.vue";
import { mdiMicrosoftExcel } from "@mdi/js";
import VFileBtn from '@/components/plugins/vuetify/v-file-btn.vue';

export default {
    components: {
        VPasswordField,
        VFileBtn
    },
    data(){
        return {
            mdiMicrosoftExcel,

            showSearch: true,

            review: {
                no: null,
                thumb: null,
                image: null,
                isMain: null,
            },

            thumb: null,
            image: null,
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            if(this.$route.params._id){
                let { review } = await api.console.easyleg.reviews.get({ _id: this.$route.params._id });
                this.review = review;
            }
            else{
                let { summary } = await api.console.easyleg.reviews.gets({ headers: { limit: 1 } });
                this.review.no = summary.totalCount + 1;
            }
        },

        async save(){
            let { review } = await api.console.easyleg.reviews[this.review._id ? "put" : "post"](this.review);

            if(this.thumb){
                let { thumb } = await api.console.easyleg.reviews.thumb.post(review._id, this.thumb);
                review.thumb = thumb;
            }

            if(this.image){
                let { image } = await api.console.easyleg.reviews.images.post(review._id, this.image, 0);
                review.images[0] = image;
            }
            
            await api.console.easyleg.reviews.put(review);

            alert("저장되었습니다");
            this.$router.push(`/console/easyleg/reviews`);
        },

        createObjectURL(url){
            try{
                return URL.createObjectURL(url)
            }
            catch(error){}
            return null;
        }
    }
}
</script>

<style scoped>
.v-text-field >>> input {
    text-align: end;
}
</style>